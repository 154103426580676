import plan from '@/plugins/components/plan/index';
export default {
  components: { plan: plan },
  data: function data() {
    return {
      hoverIdx: undefined,
      selectIdx: undefined };

  },
  methods: {
    onHover: function onHover(idx) {
      this.hoverIdx = idx;
    },
    onSelect: function onSelect(idx) {
      this.selectIdx = idx;
    },
    // 重定位
    handleReRender: function handleReRender() {
      this.$refs.plan && this.$refs.plan.init();
    } },

  computed: {
    tooltips: function tooltips() {
      return this.list[this.selectIdx];
    } } };