var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "c-box",
        { attrs: { flex: "row", height: "100%" } },
        [
          _c(
            "c-box",
            { attrs: { grow: "1" } },
            [
              _c(
                "plan",
                {
                  ref: "plan",
                  attrs: {
                    width: "908px",
                    height: "518px",
                    tooltips: { width: 100, trigger: "select" },
                    editIdx: 1,
                    hover: true,
                    select: true,
                    move: true,
                    drag: true
                  },
                  on: {
                    hover: _vm.onHover,
                    select: _vm.onSelect,
                    drag: _vm.onDrag,
                    move: _vm.onMove
                  }
                },
                [
                  _c("div", { attrs: { slot: "tooltips" }, slot: "tooltips" }, [
                    _vm._v(_vm._s(_vm.tooltips))
                  ])
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.handleRender }
                },
                [_vm._v("渲染")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.handleReRender }
                },
                [_vm._v("重新渲染")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "default" },
                  on: { click: _vm.handleCreate }
                },
                [_vm._v("新增")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }